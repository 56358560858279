body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  height: auto; /* Allow height to adjust */
  overflow-y: scroll; /* Enable scrolling */

}

body::-webkit-scrollbar {
  display: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: auto; /* Let height expand with content */
}

.homepage {
  position: relative;
  min-height: 150vh; /* Creates scroll effect before reaching fields */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Prevent centering everything */
  align-items: center;
}



.homepage .background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(2.5px);
  z-index: -1;
  pointer-events: none

}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%); /* Perfect centering */
  z-index: 1;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  text-align: center;
  width: 90%; /* Ensures proper width handling */
  max-width: 1600px; /* Prevents it from being too wide */
  align-items: center;
  


}

.centered-text h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px; /* Made font smaller */
  text-align: center;
  width: 80%;
  align-items: center;
  font-weight: 500;
  animation: fadeIn 2s ease-out backwards; /* Apply fade-in animation */
  text-shadow: 2px 2px 4px rgb(0, 76, 176);


}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 01;
  }
}




/* Fields Section Styles */
.fields-section {
  width: 100%;
  padding: 4rem;
  background-color: #f9f9f9;
  margin-top: 100vh; /* Pushes it to the bottom */
}

.fields-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

.fields-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
  justify-items: center;
}

.field-item {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; /* Smooth transition */

}

.field-item:hover {
  background-color: #006eff;
  color: #fff;
  cursor: pointer;
}

/* Fade out effect when hovering off */
.field-item:not(:hover) {
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out; /* Smooth fade-out */
}

  @media (max-width: 768px) {
    /* Adjust the centered text for smaller screens */

    .centered-text h1 {
      font-size: 21px;
      font-weight: 550;
      width: 300px;
    }

    .centered-text {
      top: 18%; /* Keep vertically centered */
      left: 50%; /* Keep horizontally centered */

    }

    /* Shift fields section items towards the left */
    .fields-grid {
      display: flex;
      flex-direction: column;
      height: 140vh; /* Make it take up the whole viewport height */

    }

    .fields-section {

      
    }
    
    .fields-header h2 {
      font-size: 1.25rem;

      
    }
    
    .fields-grid {
      

      
    }
    
    .field-item {
      align-items: center;
      
    }


}

