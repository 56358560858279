/* Container */
.misc-container {
  display: flex;
  justify-content: space-between; /* Ensures contact is on the left and FAQ is on the right */
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  gap: 2rem;
  margin-top: -0.90rem;
  z-index: 15;
  height: 120vh;
}

/* Contact Section - Left Side */
.contact-section {
  flex: 1;
  max-width: 40%;
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 17em;
  z-index: 100;
  position: relative;
}

.contact-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  z-index: 100;
}

.contact-section p {
  font-size: 1rem;
  line-height: 1.6;
  z-index: 100;
}

.contact-section a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  z-index: 100;
}

.contact-section a:hover {
  text-decoration: underline;
  z-index: 100;
}

/* FAQ Section - Right Side */
.faq-section {
  flex: 1;
  max-width: 50%;
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10em;
  z-index: 100;
  position: relative;
}

.faq-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  z-index: 100;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
}

.faq-question {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0;
  scroll-behavior: smooth;
}

.faq-answer {
  margin-top: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.5;
  scroll-behavior: smooth;
  max-height: 0; /* Start with collapsed state */
  overflow: hidden; /* Prevent content overflow */
  transition: max-height 0.3s ease-out;

}

.faq-answer.open {
  max-height: 1000px; /* Max height for expanded content */
  transition: max-height 0.3s ease-in;

}

/* Mobile Layout */
@media (max-width: 768px) {
  .spacer {
    height: 10vh;
  }
  .misc-container {
    flex-direction: column;
    padding: 1rem;
    min-height: 100vh;

  }

  .contact-section {
    max-width: 100%;
    margin-top: 10rem;
  }

  .faq-section {
    margin-top: 0rem;
    max-width: 100%;
    margin-bottom: 50vh;
    padding-bottom: 20vh;
    min-height: 120vh;

  }
}
