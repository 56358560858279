/* Global Reset for Padding and Margin */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


/* Header Styling */
.header {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  position: sticky;
  position: fixed;
  height: 7em;
  min-width: 300px;
  z-index: 20;
  box-shadow: 0 4px 8px rgb(0, 0, 0); /* Adds a strong shadow */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    
}


.header.nav-active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */

}


.header-left {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  height: 10vh;
  cursor: pointer;
  margin-left: 0.5em;
  position: sticky;
  z-index: 10;
}

.nav-bar {
  display: flex;
  gap: 10vw;

}

.find-talent-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  padding: 1vh;
  background-color: #004bb0;
  color: white;
  border: none;
  border-radius: 1.5vh;
  cursor: pointer;
  translate: -2vh;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.find-jobs-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  padding: 1vh;
  background-color: #004bb0;
  color: white;
  border: none;
  border-radius: 1.5vh;
  cursor: pointer;
  translate: -1vh;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.find-talent-btn:hover, .find-jobs-btn:hover, .misc-btn:hover {
  opacity: 0.8;
  background-color: #003d91;
}

button:focus,
button:active {
  outline: none;
  box-shadow: none;
}

.misc-btn {
    font-family: 'Roboto', sans-serif;
    font-size: 100%;
    padding: 0.5rem;
    background-color: #004bb0;
    color: white;
    border: none;
    border-radius: 1.5vh;
    cursor: pointer;
    translate: 0vh;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}


.header-right {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.email {
  font-family: 'Roboto', sans-serif;
  font-size: 0.90em;
  font-weight: bold;
  margin-right: 3.2em;
  color: #333;
}

.phone {
  font-family: 'Roboto', sans-serif;
  font-size: 0.90em;
  font-weight: bold;
  margin-right: 7em;
  color: #333;
}

.verbiage {
  display: flex;
  height: 15vh;
  margin-left: auto;
  position: sticky;
  z-index: 10;
  margin-right: auto;
  }

/* Hamburger Menu Button */
.ham-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 20;
  transition: transform 0.3s ease;
  position: relative; 
}

.ham-menu span {
  width: 30px;
  height: 5px;
  background-color: #004bb0;
  transition: all 0.3s ease;
  border-radius: 5px; 
}

@media screen and (max-width: 768px) {

  .ham-menu {
    display: flex; 
  }

  .nav-bar {
    display: none;

  }

  .logo {
    translate: -1em;
  }

  .verbiage {
    translate: -4em;
  }

  .find-talent-btn,
  .find-jobs-btn {
    display: none; 
  }

  .header-right {
    display: none; 
  }
}



.ham-menu.open span:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: 5px; 
  position: absolute; 
  top: 0; 
  top: 25%
}

.ham-menu.open span:nth-child(2) {
  opacity: 0; 
}

.ham-menu.open span:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: 5px; 
  position: absolute; 
  bottom: 0; 
  top: 75%

}

.off-screen-menu {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: -100%; 
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  transition: bottom 0.3s ease; 
  box-shadow: 0 4px 8px rgb(0, 0, 0); /* Adds a strong shadow */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  
}


.off-screen-menu.active {
  bottom: 0; 
  opacity: 1;
  visibility: visible;
}

.off-screen-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.off-screen-menu li {
  margin: 2rem 0;
  transition: opacity 0.3s ease;
}

.off-screen-menu li a {
  color: #004bb0;
  text-decoration: none;
  font-size: 2.5rem;
}





    
