.job-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 110vh;
  overflow: auto;
  padding-top: 10vh;
}

.row {
  width: 100%;
  flex-grow: 1;
}

.col-md-6 {
  padding: 20px;
}

.image-quote {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  color: #ffffff;
}

.img-fluid {
  margin-top: 150px;
}

form {
  margin-top: 5vh;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 8px;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn {
  background-color: #004bb0;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100%;
}

.btn:hover {
  background-color: #0056b3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 80%;
}

.modal-content h3 {
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .job-page {
    padding-bottom: 25vh;
  }
  .image-quote {
    font-size: 1rem;
  }

  .img-fluid {
    margin-top: 100px;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    padding: 10px;
  }

  h2 {
    font-size: 1.4rem;
  }

  .btn {
    padding: 12px 20px;
  }
}
