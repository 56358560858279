.tos-container {
    padding: 7vw;
    max-width: 1000px; 
    margin: 0 auto;
    overflow-y: auto; 
}

.title {
    text-align: center;
}

.tos-container::-webkit-scrollbar {
display: none;
}

@media screen and (max-width: 768px) {
    .tos-container {
        padding: 7vw;
        max-width: min(90vw, 600px); 
        margin: 0 auto;
        overflow-y: auto; 
        margin-top: 120px;
        align-items: center;
        font-size: 0.75rem;
        padding-bottom: 15vh;
    }

    .title {
        font-size: 36px;
    }
    
}



