html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.main-content {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Footer should stick to the bottom of the screen */
footer {
  position: fixed; /* Fixes the footer at the bottom */
  bottom: 0; /* Sticks the footer to the bottom */
  left: 0; /* Sticks it to the left side */
  width: 100%; /* Makes it span the whole width */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; 
  color: rgb(0, 0, 0);
  padding: 10px;
  height: 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  font-family: 'Open Sans', sans-serif;
  z-index: 1000; /* Make sure it's above other content */
  font-family: 'Inter', sans-serif;
  text-align: center;
  box-shadow: 0 4px 8px rgb(0, 0, 0); /* Adds a strong shadow */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


footer p {
  margin: 0 15px;
  font-size: 10px;
  white-space: nowrap;
}

.linkedin-icon {
  width: 22px; 
  height: auto;
  margin-left: 4px; 
  transition: opacity 0.3s ease; 
}

.linkedin-icon:hover {
  opacity: 0.7; 
}

@media screen and (max-width: 768px) {
  footer {
    flex-direction: column;
    height: auto;
    padding: auto;
  }

  
}
